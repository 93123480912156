import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@/shared/utils'

import type { PlayerAtTable } from '../model/user.types'

export default function useUserTitles(user: PlayerAtTable) {
	const { t } = useTranslation()

	const [title, setTitle] = useState(user.username)
	const [subTitle, setSubTitle] = useState(t('waiting'))

	useEffect(() => {
		if (user.winner) {
			setTitle(t('win'))
			setSubTitle(`+${formatNumber(user.winner)}$`)
		} else if (user.last_action) {
			const isAllin = ['raise', 'call'].includes(user.last_action) && user.player_balance <= 0
			if (isAllin) setTitle(t('game_action-all-in'))
			else
				switch (user.last_action) {
					case 'fold': {
						setTitle(t('game_action-fold'))
						break
					}
					case 'check': {
						setTitle(t('game_action-check'))
						break
					}
					case 'call': {
						setTitle(t('game_action-call'))
						break
					}
					case 'raise': {
						setTitle(t('game_action-raise', { raise: `${user.last_bet}$` }))
						break
					}
				}
		} else setTitle(user.username)

		if (user.player_balance) setSubTitle(`${user.player_balance}$`)
		else setSubTitle(t('waiting'))
	}, [t, user.last_action, user.last_bet, user.player_balance, user.username, user.winner])

	return [title, subTitle] as const
}
