import { useGameActions } from '..'
import { useEffect } from 'react'

import { useUserStore } from '@/entities/user'

import { useGameStore } from '../model/game.store'

export function useGameUser() {
	const {
		playerSeat,
		game: { chairs }
	} = useGameStore()

	const { tg_uid } = useUserStore()
	const { gameSetPlayerSeat } = useGameActions()

	useEffect(() => {
		if (playerSeat === null) {
			const relativeSeat = chairs.findIndex(p => p?.id === tg_uid)
			if (~relativeSeat) gameSetPlayerSeat(relativeSeat)
			else return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerSeat, tg_uid, gameSetPlayerSeat])

	if (playerSeat === null) return null
	else return chairs[playerSeat - 1]
}
