import cn from 'classnames'
import { memo } from 'react'

import { PlayerCard } from '@/entities/game/ui/PlayerCard/PlayerCard'

import { Chip } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { useUserInGame } from '../../hooks/useUserInGame'
import useUserTitles from '../../hooks/useUserTitles'
import type { UserInGameProps } from '../../model/user.types'

import c from './UserInGame.module.scss'

import { UserInGameAvatar } from './UserInGameAvatar'

export const UserInGame = memo(function UserInGame(user: UserInGameProps) {
	const { pos, turnData, roundData, isPlayer, isBetDisplay } = useUserInGame(user)
	const [title, subtitle] = useUserTitles(user)

	const userModifer = user.winner ? c['--winner'] : !user.active_player ? c['--inactive'] : null
	const cardsShown = user.cards_shown && !isPlayer ? c['--cards-shown'] : null

	return (
		<div
			className={cn(c['user-in-game'], isPlayer && c['--player'], cardsShown, userModifer)}
			data-game-seat={user.seat}
			data-seat-index={user.index}
			style={pos}>
			{/* User data */}
			<div className={c['user-in-game__data']}>
				<UserInGameAvatar turn={turnData} />
				<span className='user-in-game__data--username'>{title}</span>
				<span className={c['user-in-game__data--bank']}>{subtitle}</span>
			</div>

			{/* Cards */}
			<div className={c['user-in-game__hand']}>
				{user.hand.map(c =>
					c ? (
						<PlayerCard
							key={`card-${c.name}`}
							isPlayer={isPlayer}
							name={c.name}
							in_combo={c.in_combo}
						/>
					) : null
				)}
			</div>

			{/* Blind / Dealer */}
			<span className={c['user-in-game__blind']}>
				{roundData.isDealer ? <Chip isDealer /> : roundData.isBB ? <span>BB</span> : null}
				{roundData.isSB && <span>SB</span>}
			</span>

			{/* Bet */}
			{isBetDisplay && (
				<span className={c['user-in-game__bet']}>
					{formatNumber(Number(user.last_bet))} <Chip color='var(--color-gold-2)' />
				</span>
			)}

			{/* {user.combo && (
				<div className={c['user-in-game__combo']}>
					<span>{user.combo}</span>
				</div>
			)} */}
		</div>
	)
})
