import { useOverlay } from '@/components/Overlay'

import { UserCard } from '../UserCard/UserCard'
import { UserData } from '../UserData/UserData'
import { UserStatistic } from '../UserStatistic/UserStatistic'

import c from './UserMiniProfile.module.scss'

export function UserMiniProfile() {
	const [, setUserOverlayVisible] = useOverlay('user-mini-profile')
	const onClose = () => setUserOverlayVisible()

	return (
		<div className={c['user-mini-profile']}>
			<UserCard onClose={onClose}>
				<UserData direction='vertical' />
				<UserStatistic />
			</UserCard>
		</div>
	)
}
